#scheduler-month-calendar .rbc-row .rbc-row-segment {
   display: flex;
   justify-content: center;
}


#scheduler-month-calendar .rbc-row .rbc-show-more {
   width: 80%;
   background-color: #F5F5F5;
   font-size: 10px;
   border-radius: 5px;
   padding: 3px;
}

#scheduler-month-calendar .rbc-today {
   background-color: white;
}


.filtering .rbc-time-view,
.filtering .rbc-month-view {
   opacity: 0.5;
}