.view-chip{
    border: 1px solid #FBCF34;
    color: #000000;
    border-radius: 8px;
    padding: 2px 6px;
    gap: 4px;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    display: flex;
    align-items: center;
}
.possession-chip{
    border: 1px solid #EFF1F5;
    background-color: #EFF1F5;
    color: #023047;
    border-radius: 28px;
    padding: 2px 6px;
    gap: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
}