.service-stepper {
    width: 50%;
}
.service-stepper .ant-steps-item:first-child {
    text-align: left !important;
    padding-inline-start: 16px !important;
}

.service-stepper .ant-steps-item:last-child {
    padding-inline-start: 32px !important;
}

.service-stepper .ant-steps-item::before{
    background-color: transparent !important;
}


.request-serivice-collapse .ant-collapse-header,
.request-serivice-collapse .ant-collapse-content-box {
    padding-left: 0 !important;
}
