.week-table {
    width: 100%;
    border-collapse: collapse;
}

.week-table th,
.week-table td {
    border: 1px solid #ddd;
    padding: 5px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    width: 190px;

}

.week-table td:first-child {
    width: 20px !important;
    background-color: transparent;
    color: #616161;
    font-weight: 400;
}

.week-table th {
    background-color: transparent;
}

.week-table .week {
    background-color: #f2f2f2;
    font-weight: bold;
}

.week-table td {
    height: 120px;
}