@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        /* Rotate in the opposite direction */
    }
}

.rotate {
    animation: rotate 3s linear forwards;
    ;
    /* Rotate for 3 seconds infinitely */
}

.ant-table-body {
    /* min-height: 180px !important; */
}