/* day custom calender */

/* custom-day-calender
> resources
.rbc-time-view
.rbc-time-header
.rbc-time-header-content
.rbc-time-header-content
.rbc-row 
*/


/* custom-day-calender
header empty space
.custom-day-calender
.rbc-time-view
.rbc-time-header
.rbc-time-header-content
.rbc-time-header-content
.rbc-row 
.rbc-allday-cell
*/


.custom-day-calender>.rbc-time-view>.rbc-time-header>.rbc-time-header-content>.rbc-row-resource>.rbc-header {

    height: 40px;
}

.custom-day-calender>.rbc-time-view>.rbc-time-header>.rbc-time-header-content {
    width: 50px;

}

.day-custom-calender>.rbc-time-view>.rbc-time-header>.rbc-time-header-content>.rbc-allday-cell {
    height: 0px !important;

}



.day-custom-calender>.rbc-time-view>.rbc-time-header>.rbc-time-header-content>.rbc-row.rbc-row-resource {
    border-bottom: none !important;

}

.day-custom-calender>.rbc-time-view>.rbc-time-header>.rbc-time-header-content>.rbc-row-resource>div {


    background: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;

}

.rbc-time-view .rbc-row {
    /* height: 30px; */

}


.day-custom-calender>.rbc-time-view>.rbc-time-content>.rbc-day-slot>.rbc-events-container {

    /* width: 150px; */
}

.day-custom-calender>.rbc-time-view>.rbc-time-content>.rbc-day-slot {
    min-width: none;
    /* width: 80px; */
    /* border: none; */
}

/* .rbc-label {
    color: #667085;
    font-weight: 400;
    font-size: 12px;
    min-width: 62px !important;
} */

.day-custom-calender>.rbc-time-view>.rbc-time-header>.rbc-time-header-content>.rbc-row-resource>.rbc-header {
    color: #424242;
    font-weight: 500;
    font-size: 13px;
    border: none !important;

    /* height: 30px; */
}

/* rbc-label rbc-time-header-gutter */