.custom-popup {
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: -1 2px 8px rgba(0, 0, 0, 0.2);
    font-family: Arial, sans-serif;
    width: 250px;
  }
  
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .popup-time-block {
    font-size: 15px;
    font-weight: 500;
    color: black;
  }
  .popup-status {
    background-color: #ebf0f5;
    color: rgb(50 148 227);
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
  }
  
  .popup-content p {
    margin: 5px 0;
  }
  
  .popup-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  