#scheduler-day-calendar .rbc-time-view{
    margin-top: 30px;
}
#scheduler-day-calendar .rbc-event-label{
    display: none;
}
#scheduler-day-calendar .rbc-day-slot{
    background-color: white;
}

#scheduler-day-calendar .rbc-time-header{
    display: none;
}

#scheduler-day-calendar .rbc-time-content{
    border-top: none;
    overflow-x: scroll;
}

#scheduler-day-calendar .rbc-time-content::-webkit-scrollbar {
    width: 0;
}


#scheduler-day-calendar .rbc-timeslot-group{
    min-height: 60px;
}
#scheduler-day-calendar .rbc-calendar .rbc-time-view{
    border: none;
}

#scheduler-day-calendar .rbc-events-container{
    min-width: 100%;
}

#scheduler-day-calendar .rbc-time-gutter{
    position: sticky;
    left: 0;
    background-color: white;
}


#scheduler-day-calendar .rbc-text{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}