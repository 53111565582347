@import url('./assets/font/helvaticaNeue-font-family.css');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Helvetica Neue', sans-serif !important;
}

html,
body {
  width: 100% !important;
}

input {
  font-family: 'Helvetica Neue', sans-serif !important;
}

.ant-table-thead {
  font-family: 'Helvetica Neue', sans-serif !important;

}

.ant-table-tbody {
  font-family: 'Helvetica Neue', sans-serif !important;
}

.ant-table-thead>tr>th {
  font-weight: 500 !important;
  font-size: 14px;
}





code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapperClassName2 {
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(27, 27, 27, 0.25);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

h1,
h2,
h3,
h4,
h5,
span,
h6 {
  font-family: 'Helvetica Neue', sans-serif !important;
}

button {
  background-color: transparent;
  border: none;
}

.cursorPointer {
  cursor: pointer;
}

.dropdown-item:focus {
  outline: none !important;
}

.dropdown-button:active {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.65) !important;
}


a {
  color: inherit !important;
  text-decoration: none !important;
  cursor: pointer;
}

input::placeholder {
  padding: 10px 0px;
  /* Adjust placeholder padding */

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ant-select-selector {
  min-height: 40px;
}