.rbc-label {
  font-size: 12px;
  font-weight: 400;
  color: #344563;
  font-family: 'Rubik';
}

.rbc-current-time-indicator {
  background-color: #E9BD5A;
  height: 2px;
}

.rbc-current-time-indicator:before {
  content: "";
  display: block;
  background: url("../../../assets/images/play.png") no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: -1px;
}

.rbc-header {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #7D8DA1;
  height: 50px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.0001);
}

.rbc-header>span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: #666666;
  margin-top: 15px;
}

.rbc-header:hover {
  color: #E9BD5A;
}

.rbc-today {
  color: #E9BD5A;
}

.rbc-btn-group>button {
  border: 1px solid #E9EDF0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #000000;
}

.rbc-btn-group>button:hover {
  color: #E9BD5A;
}

.rbc-active {
  color: #E9BD5A;
}

.rbc-button-link {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.28px;
  color: #111111;
}

.rbc-current>.rbc-button-link {
  color: #E9BD5A;
}

.rbc-off-range>.rbc-button-link {
  color: #DADADA;
}

.rbc-off-range-bg {
  background-color: #fff;
}

/* .rbc-event{
  background-color: #0496FF33;
}*/
.rbc-event-label,
.rbc-event-content {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;
}

.ant-select-selectorv2 .ant-select-selector {
  background: rgb(245, 245, 245) !important;
}