.customModal>.ant-modal-content>.ant-modal-header {
    border-bottom: 1px solid #e7e6e6 !important;
    padding-bottom: 5px !important;
    margin-bottom: 11px !important;
}

/* .ant-modal-body {
    height: 81vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 4px !important;
} */