.PhoneInputInput {
    height: 40px;
    width: 100%;
    border: 1px solid rgb(214, 217, 225);
    border-radius: 8px;
    margin: 0px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    font-family: "Helvetica Neue", Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 57px;
    color: rgb(0, 0, 0);
}

.flag-dropdown {
    margin-right: 10px;
}

.react-tel-input .form-control {
    height: 40px;
    width: 100%;
    border: 1px solid rgb(214, 217, 225);
    border-radius: 8px;
    margin: 0px;

    box-sizing: border-box;
    outline: none;
    font-family: "Helvetica Neue", Arial;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 57px;
    color: rgb(0, 0, 0);
}

.react-tel-input .form-control:focus {
    box-shadow: none;
}


.react-tel-input .flag-dropdown {
    background-color: transparent;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

}

.react-tel-input .flag-dropdown:hover {
    background-color: transparent;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

}

.react-tel-input .flag-dropdown.open:hover {}

.react-tel-input,
.react-tel-input .selected-flag:hover {
    /* Resetting properties to default values */
    border: none !important;
    /* Remove border */
    background-color: transparent !important;
    /* Remove background color */
    /* Add any other properties that need to be reset */
}