/* fonts.css */

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'), url('./HelveticaNeueUltraLight.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'), url('./HelveticaNeueLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'), url('./HelveticaNeueRoman.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'), url('./HelveticaNeueMedium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'), url('./HelveticaNeueBold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue';
    src: local('Helvetica Neue'), url('./HelveticaNeueHeavy.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}