#scheduler-week-calendar .rbc-allday-cell {
    display: none;
}

#scheduler-week-calendar .rbc-event-label{
    display: none;
}

#scheduler-week-calendar .rbc-time-header {
    background-color: rgb(238, 238, 238);
}

#scheduler-week-calendar .rbc-time-header .rbc-today {
    background-color: rgb(238, 238, 238);
}

#scheduler-week-calendar .rbc-time-header .rbc-header {
    display: flex;
}

#scheduler-week-calendar .rbc-today {
    background-color: white;
}

#scheduler-week-calendar .rbc-events-container {
    margin-right: 0;
}

#scheduler-week-calendar .rbc-event {
    max-width: 120px;
}

#scheduler-week-calendar .rbc-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.filtering .rbc-time-view,
.filtering .rbc-month-view {
    opacity: 0.5;
}