.custom-header {
    font-size: 14px;
    border: none !important;

    font-weight: 400 !important;
    overflow: hidden !important;

}

.custom-header .ant-table-cell {
    white-space: nowrap;
    font-size: 12px;
    padding: 0px 0px !important;
    margin: 0px !important;
    text-align: center !important;
    /* background-color: transparent !important; */

    font-weight: 400 !important;
    color: #667085 !important;
    height: 10px !important;
}



.search-column {
    border-left: none !important;
    background-color: #F9F9F9;
    padding-right: 10px !important;


}

.custom-header tr>td {
    padding: 0px !important;
    /* Adjust padding as needed */

}

.custom-header .ant-table-thead>tr>th {
    border: none !important;
    background-color: transparent;

}

.custom-header .ant-table-thead>tr>td {
    border: none !important;


}

.custom-header .ant-table-thead>tr>th:nth-child(1) {
    border-right: 1px solid #F9F9F9 !important;
    background-color: transparent;
    padding: 10px !important;


}

.custom-header .ant-table-container {
    border: none !important;
}

.timeSlotColumn {
    height: 0px !important;
    padding: 0px !important;


}